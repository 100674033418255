<template>
<div>

    <b-row>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-edit fa-md"></i> <span class="h5"> Gestión de perfil</span>
                </CCardHeader>
                <CCardBody>
                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                        <b-form @submit.stop.prevent="handleSubmit(registrarPerfil)">
                            <b-row>
                                <b-col md="12">
                                    <b-tabs content-class="mt-3" active-nav-item-class="font-weight-bold text-uppercase text-dark">
                                        <b-tab title="Datos" active>
                                            <b-row>
                                                <b-col lg="6">
                                                    <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                                                        <b-form-group label="Nombre:" class="mb-2">
                                                            <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre" :value="datosPerfil.nombre" @change="datosPerfil.nombre = $event"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                        <b-tab title="Permisos">
                                            <b-row>
                                                <b-col md="12" class="my-3">
                                                    <span class="h6 text-muted">Si la opción Ver de una sección no está marcada, tampoco podrás ver sus hijos</span>
                                                </b-col>
                                                <b-col md="12" class="mb-1">
                                                    <b-dropdown :disabled="disabled" split @click="seleccionarTodos()" size="sm" variant="dark" class="m-2 show-arrow-down-dropdown-button">
                                                        <template #button-content>
                                                            <i class="fas fa-check-square"></i> Seleccionar todo
                                                        </template>
                                                        <b-dropdown-item size="sm" @click="seleccionarChbx('r')">Solo ver</b-dropdown-item>
                                                        <b-dropdown-item @click="seleccionarChbx('c')">Solo crear</b-dropdown-item>
                                                        <b-dropdown-item @click="seleccionarChbx('u')">Solo modificar</b-dropdown-item>
                                                        <b-dropdown-item @click="seleccionarChbx('d')">Solo eliminar</b-dropdown-item>
                                                    </b-dropdown>
                                                    <b-button :disabled="disabled" size="sm" variant="dark" class=" mr-2" @click="limpiarTodos()">
                                                        <i class="far fa-square"></i> Limpiar todo
                                                    </b-button>
                                                </b-col>
                                                <b-col md="6" class="mt-3" v-for="column in columns" :key="column.index">
                                                    <ul>
                                                        <div v-for="permiso in column" :key="permiso.idModulo">
                                                            <div v-if="permiso.visible == 1">
                                                                <template v-if="permiso.tipo == 1 ">
                                                                    <li>
                                                                        <hr class="my-2">
                                                                        <div class="vr">
                                                                            <span class="h6">{{permiso.nombre}}</span>
                                                                            <b-form-group class="my-1">
                                                                                <b-form-checkbox-group plain :options="permiso.options" v-model.lazy="permiso.data"></b-form-checkbox-group>
                                                                            </b-form-group>
                                                                        </div>
                                                                    </li>
                                                                </template>
                                                                <template v-if="permiso.tipo == 2">
                                                                    <ul>
                                                                        <li>
                                                                            <div class="vr">
                                                                                <span class="h6">{{permiso.nombre}} </span>
                                                                                <b-form-group class="my-1">
                                                                                    <b-form-checkbox-group plain :options="permiso.options" v-model.lazy="permiso.data"></b-form-checkbox-group>
                                                                                </b-form-group>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </template>
                                                                <template v-if="permiso.tipo == 3">
                                                                    <ul>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="vr">
                                                                                    <span class="h6">{{permiso.nombre}}</span>
                                                                                    <b-form-group class="my-1">
                                                                                        <b-form-checkbox-group plain :options="permiso.options" v-model.lazy="permiso.data"></b-form-checkbox-group>
                                                                                    </b-form-group>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </ul>
                                                                </template>
                                                            </div>

                                                        </div>
                                                    </ul>
                                                </b-col>
                                            </b-row>
                                        </b-tab>
                                    </b-tabs>
                                </b-col>
                                <!-- <template v-if="permiso.tipo == 4">
                                                <ul class="x">
                                                    <ul class="y">
                                                        <ul class="z">
                                                            <li>
                                                                <div class="vr">
                                                                    <span class="h6">{{permiso.nombre}} {{permiso.idModulo}}</span>
                                                                    <b-form-group class="my-1">
                                                                        <b-form-checkbox-group plain :options="permiso.options" v-model="permiso.data"></b-form-checkbox-group>
                                                                    </b-form-group>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </ul>
                                                </ul>
                                            </template> -->
                                <b-col lg="12" class="text-center my-2">
                                    <b-button :disabled="disabled" size="md" variant="dark" class=" mr-2" :to="{name: 'Perfiles'}">
                                        <i class="fas fa-arrow-left"></i> Volver
                                    </b-button>
                                    <b-button v-if="$route.params.id && checkPermissions('012-USU-PER','u')==1" :disabled="disabled" size="md" variant="success" type="submit">
                                        <i class="fas fa-save"></i> Guardar
                                    </b-button>
                                    <b-button v-if="!$route.params.id && checkPermissions('012-USU-PER','c')==1" :disabled="disabled" size="md" variant="success" type="submit">
                                        <i class="fas fa-save"></i> Guardar
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </validation-observer>
                </CCardBody>

            </CCard>
        </b-col>
    </b-row>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    data() {
        return {
            datosPerfil: {
                idTipoPerfil: null,
                nombre: '',
                idCliente: '',
                permisos: []
            },
            radioPermisos: [{
                value: 1,
                text: "Si"
            }, {
                value: 2,
                text: "No"
            }],
            disabled: false,
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        seleccionarChbx(x) {
            for (let j in this.datosPerfil.permisos) {
                let datax = [];
                let c, r, u, d;
                //debe seleccionar si existe el crud
                if (this.datosPerfil.permisos[j].model.includes('r') && x == 'r') {
                    r = 'r'
                    datax.push(r);
                };
                if (this.datosPerfil.permisos[j].model.includes('c') && x == 'c') {
                    c = 'c'
                    datax.push(c);
                };
                if (this.datosPerfil.permisos[j].model.includes('u') && x == 'u') {
                    u = 'u'
                    datax.push(u);
                };
                if (this.datosPerfil.permisos[j].model.includes('d') && x == 'd') {
                    d = 'd'
                    datax.push(d);
                };
                this.datosPerfil.permisos[j].data = datax;
            }
        },
        seleccionarTodos() {
            for (let j in this.datosPerfil.permisos) {
                let datax = [];
                let c, r, u, d;
                //debe seleccionar si existe el crud
                if (this.datosPerfil.permisos[j].model.includes('r')) {
                    r = 'r'
                    datax.push(r);
                };
                if (this.datosPerfil.permisos[j].model.includes('c')) {
                    c = 'c'
                    datax.push(c);
                };
                if (this.datosPerfil.permisos[j].model.includes('u')) {
                    u = 'u'
                    datax.push(u);
                };
                if (this.datosPerfil.permisos[j].model.includes('d')) {
                    d = 'd'
                    datax.push(d);
                };

                this.datosPerfil.permisos[j].data = datax;
            }
        },
        limpiarTodos() {
            for (let j in this.datosPerfil.permisos) {
                this.datosPerfil.permisos[j].data = [];
            }
        },

        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        obtenerPerfil() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-perfil", {
                    params: {
                        idTipoPerfil: me.$route.params.id,
                        idCliente: me.datosPerfil.idCliente
                    }
                })
                .then(function (response) {
                    me.datosPerfil.idTipoPerfil = response.data[0].idTipoPerfil;
                    me.datosPerfil.nombre = response.data[0].nombre;
                    me.obtenerPermisosPerfil();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                    me.$router.push({
                        name: 'Perfiles',
                    });
                });

        },
        listarPermisosPerfil() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-permisos-perfil")
                .then(function (response) {
                    // let listaPermisos = r;
                    for (let k in response.data) {
                        let visiblex = 1;
                        let optionsx = [];
                        let c, r, u, d;

                        if (!me.$store.state.modules.find(x => x.identificador == 'SGC') && response.data[k].codigo.includes('9001')) {
                            visiblex = 0;
                        } else if (!me.$store.state.modules.find(x => x.identificador == 'SGSI') && response.data[k].codigo.includes('27001')) {
                            visiblex = 0;
                        } else if (!me.$store.state.modules.find(x => x.identificador == 'SST') && response.data[k].codigo.includes('45001')) {
                            visiblex = 0;
                        } else if (!me.$store.state.modules.find(x => x.identificador == 'SGMA') && response.data[k].codigo.includes('14001')) {
                            visiblex = 0;
                        } else if (!me.$store.state.modules.find(x => x.identificador == 'VCM') && response.data[k].codigo.includes('VCM')) {
                            visiblex = 0;
                        }

                        if (response.data[k].valorD.includes('r')) {
                            r = {
                                text: 'Ver',
                                value: 'r'
                            }
                            optionsx.push(r);
                        };
                        if (response.data[k].valorD.includes('c')) {
                            c = {
                                text: 'Crear',
                                value: 'c'
                            }
                            optionsx.push(c);
                        };
                        if (response.data[k].valorD.includes('u')) {
                            u = {
                                text: 'Modificar',
                                value: 'u'
                            }
                            optionsx.push(u);
                        };
                        if (response.data[k].valorD.includes('d')) {
                            d = {
                                text: 'Eliminar',
                                value: 'd'
                            }
                            optionsx.push(d);
                        };

                        me.datosPerfil.permisos.push({
                            idModulo: response.data[k].idModulo,
                            tipo: response.data[k].tipo,
                            nombre: response.data[k].descripcion,
                            model: response.data[k].valorD,
                            options: optionsx,
                            visible: visiblex,
                            data: [],
                        })
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                    me.$router.push({
                        name: 'Perfiles',
                    });
                });
        },
        registrarPerfil() {
            let me = this;
            me.swat('info', 'Esto va demorar un poco, espere por favor...')
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-perfil",
                    me.datosPerfil, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);

                    me.$router.push({
                        name: 'Perfiles',
                    });
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;

                });
        },
        obtenerPermisosPerfil() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-permisos-perfil", {
                    params: {
                        idTipoPerfil: me.datosPerfil.idTipoPerfil,
                    }
                })
                .then(function (response) {

                    for (let k in response.data) {

                        let optionsx = [];
                        let c, r, u, d;
                        let visiblex = 1;
                        if (!me.$store.state.modules.find(x => x.identificador == 'SGC') && response.data[k].codigo.includes('9001')) {
                            visiblex = 0;
                        } else if (!me.$store.state.modules.find(x => x.identificador == 'SGSI') && response.data[k].codigo.includes('27001')) {
                            visiblex = 0;
                        } else if (!me.$store.state.modules.find(x => x.identificador == 'SST') && response.data[k].codigo.includes('45001')) {
                            visiblex = 0;
                        } else if (!me.$store.state.modules.find(x => x.identificador == 'SGMA') && response.data[k].codigo.includes('14001')) {
                            visiblex = 0;
                        } else if (!me.$store.state.modules.find(x => x.identificador == 'VCM') && response.data[k].codigo.includes('VCM')) {
                            visiblex = 0;
                        }

                        if (response.data[k].valorD.includes('r')) {
                            r = {
                                text: 'Ver',
                                value: 'r'
                            }
                            optionsx.push(r);
                        };
                        if (response.data[k].valorD.includes('c')) {
                            c = {
                                text: 'Crear',
                                value: 'c'
                            }
                            optionsx.push(c);
                        };
                        if (response.data[k].valorD.includes('u')) {
                            u = {
                                text: 'Modificar',
                                value: 'u'
                            }
                            optionsx.push(u);
                        };
                        if (response.data[k].valorD.includes('d')) {
                            d = {
                                text: 'Eliminar',
                                value: 'd'
                            }
                            optionsx.push(d);
                        };

                        me.datosPerfil.permisos.push({
                            idModulo: response.data[k].idModulo,
                            idPermisoPerfil: response.data[k].idPermisoPerfil,
                            tipo: response.data[k].tipo,
                            model: response.data[k].valorD,
                            nombre: response.data[k].descripcion,
                            options: optionsx,
                            data: [
                                response.data[k].c == 1 ? 'c' : '',
                                response.data[k].r == 1 ? 'r' : '',
                                response.data[k].u == 1 ? 'u' : '',
                                response.data[k].d == 1 ? 'd' : '',
                            ],
                            visible: visiblex
                        })
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                    me.$router.push({
                        name: 'Perfiles',
                    });
                });
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        // async obtenerId() {
        //     this.datosPerfil.idCliente = await this.$store.getters.getUser()
        // },
    },
    computed: {
        columns() {
            let listaPermisos = this.datosPerfil.permisos.filter(x => x.visible == 1);
            if (listaPermisos.length > 0) {
                let columns = []
                let mid = Math.ceil(listaPermisos.length / 2)
                for (let col = 0; col < 2; col++) {
                    columns.push(listaPermisos.slice(col * mid, col * mid + mid))
                }
                return columns
            }
        }
    },
    async created() {
        // this.obtenerId();
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosPerfil.idCliente = user.uidClient;
            if (this.$route.params.id) {
                this.obtenerPerfil();
            } else {
                this.listarPermisosPerfil();

            }
        }
    }

}
</script>
